import { Filter } from 'libs/brand-system/src/components/Filter';
import { CardsList } from 'libs/brand-system/src/templates/CardsList';
import { useFilter } from 'libs/brand-system/src/utils/useFilter';
import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { BlogCardLayout } from 'libs/growth-platform-brand-system-v2/src/layouts/BlogCardLayout';
import { textTitle1 } from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { track } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import {
  applyBgColor,
  applyBgDarkColor,
} from 'libs/brand-system/src/utils/utils';
import { morphism } from 'morphism';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { schemaBlogCardLayout } from '../../../morphismSchema/layouts/schemaBlogCardLayout';
import { schemaCardButtonLink } from '../../../morphismSchema/schemaCardButtonLinkProps';
import { getCategories, getFormats, getResources } from './logic';
import './styles.scss';

export interface IResourcesFilter {
  hubspotResources: [];
  categoryFilterLabel: string;
  formatFilterLabel: string;
  locale: string;
  className?: string;
  noResultText: string;
  seeMoreButtonLabel: string;
  sectionTitle: string;
  backgroundColor?: string;
  isDark?: boolean;
  section?: string;
  keywords?: [];
}

const ResourcesFilter: FC<IResourcesFilter> = ({
  hubspotResources,
  categoryFilterLabel,
  formatFilterLabel,
  noResultText,
  locale,
  className,
  seeMoreButtonLabel,
  sectionTitle,
  backgroundColor = 'white',
  isDark = false,
  section = 'models',
  keywords,
}) => {
  const [categoriesSelected, setSelectedCategories] = useFilter();
  const [formatsSelected, setSelectedFormats] = useFilter();
  const [filteredResources, setFilteredResources] = useState([]);

  const screenSize = useScreenSize();

  const categories = useMemo(
    () => getCategories(hubspotResources, keywords),
    [hubspotResources, keywords],
  );
  const formats = useMemo(
    () => getFormats(hubspotResources, keywords),
    [hubspotResources, keywords],
  );
  const resources = useMemo(
    () => getResources(hubspotResources, keywords),
    [hubspotResources, keywords],
  );

  useEffect(() => {
    const filtered = resources.reduce((acc: any, resource: any) => {
      let categoryFit = false;
      let formatFit = false;

      categoryFit = categoriesSelected.some(
        (category) => resource.category === category,
      );
      formatFit = formatsSelected.some((format) => resource.format === format);

      if (
        (categoriesSelected.length &&
          formatsSelected.length &&
          categoryFit &&
          formatFit) ||
        (categoriesSelected.length && !formatsSelected.length && categoryFit) ||
        (!categoriesSelected.length && formatsSelected.length && formatFit)
      ) {
        acc.push(resource);
      }
      return acc;
    }, []);

    setFilteredResources(filtered);

    if (categoriesSelected.length || formatsSelected.length) {
      track({
        gtm: {
          event: 'Clicked on filter button',
          properties: {
            pageType: 'content',
            name: 'resources-toolbox',
            resourceType: section,
            filterType: categoryFilterLabel,
            filterName: categoriesSelected[categoriesSelected.length - 1],
            countryCode: locale,
          },
        },
      });
    }
  }, [
    categoriesSelected,
    categoryFilterLabel,
    formatsSelected,
    locale,
    resources,
    section,
  ]);

  const resourcesToRender = useMemo(() => {
    if (filteredResources.length) return filteredResources;
    if (!categoriesSelected.length && !formatsSelected.length) return resources;

    return [];
  }, [
    filteredResources,
    categoriesSelected.length,
    formatsSelected.length,
    resources,
  ]);

  const resourcesCards = resourcesToRender.map((resource: any, key: any) => {
    const { button, ...linkProps } = morphism(schemaCardButtonLink, {
      ctaButton: resource,
      screenSize,
      locale,
    });

    const { linkProps: _linkProps, ..._ressource } = morphism(
      schemaBlogCardLayout,
      {
        ...resource,
        ...linkProps,
      },
    );

    return (
      <ButtonTracking
        component={BlogCardLayout}
        {..._ressource}
        {..._linkProps}
        section={'cardsList'}
        index={key}
        ctaButton={
          button?.props ? (
            <ButtonV2
              {...{
                ...button?.props,
                className: 'text-21',
                rel: null,
                component: null,
              }}
            />
          ) : undefined
        }
        labelTracking={button.title}
        className="resourceCards"
        key={key}
      />
    );
  });

  if (!resources.length) return null;
  return (
    <section
      className={`${className} pb-12 lg:pb-12 ${
        isDark
          ? applyBgDarkColor(backgroundColor)
          : applyBgColor(backgroundColor)
      }`}
      id={sectionTitle.replace(/\W+/g, '-')}
    >
      <div className="flex justify-center pt-48">
        <h2
          className={classNames(
            textTitle1,
            `my-24 font-medium`,
            isDark ? 'text-neutral-1' : '',
          )}
        >
          {sectionTitle}
        </h2>
      </div>
      {categoryFilterLabel && (
        <div className="container">
          <div className="relative flex gap-16 mb-64">
            {categoryFilterLabel && (
              <div className="z-30">
                <Filter
                  buttonTitle={categoryFilterLabel}
                  availableFilters={categories}
                  selectedFilters={categoriesSelected}
                  locale={locale}
                  onFilterClick={setSelectedCategories}
                />
              </div>
            )}
            {formatFilterLabel && (
              <div className="z-10">
                <Filter
                  buttonTitle={formatFilterLabel}
                  availableFilters={formats}
                  selectedFilters={formatsSelected}
                  locale={locale}
                  onFilterClick={setSelectedFormats}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {resourcesToRender.length ? (
        <CardsList
          cards={resourcesCards}
          backgroundColor={backgroundColor}
          isDark={isDark}
          cardsContainerClass="flex flex-wrap -mt-32 lg:-mt-36 gap-12"
          buttonType="minimal"
          shouldDisplay={9}
          seeMoreBatchSize={screenSize === 'md' ? 2 : 3}
          seeMoreButtonLabel={seeMoreButtonLabel}
          className="pb-48 sm:pb-64 md:pb-104"
        />
      ) : (
        <p
          className={`container ${
            isDark ? 'text-neutral-1' : ''
          } pb-48 sm:pb-64 md:pb-104`}
        >
          {noResultText}
        </p>
      )}
    </section>
  );
};

export default ResourcesFilter;
